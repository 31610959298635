import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 373,
    minHeight: 187,
    margin: '0 auto'
  },
  paper: {
    backgroundColor: '#F6F6F6',
    border: '1px solid #DDDDDD',
    borderRadius: '10px 10px 0 0',
    padding: '15px 15px 5px 15px'
  },
  title: {
    fontSize: 18,
    lineHeight: '21px',
    fontWeight: 'bold',
    marginBottom: 5
  },
  questionBox: {
    borderRadius: '0 0 10px 10px',
    padding: '10px 15px'
  },
  innerText: {
    fontSize: 18,
    lineHeight: '18px',
    fontWeight: 'bold',
    marginLeft: 15
  },
  liveChatLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  comodo: {
    maxWidth: 101,
    width: '100%'
  },
  mcfee: {
    maxWidth: 144,
    width: '100%'
  },
  image: {
    width: '100%',
    minHeight: 79
  },
  imageBox: {
    position: 'relative',
    width: '100%',
    height: 80,

    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',

    '& img': {
      width: '100% !important',
      height: '100% !important'
    }
  }
}));
