import React from 'react';
import { Typography, Box } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './styled';
import StyledLabel from '../StyledLabel';

export const InfoIcon = props => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, props.className)}>
      <div
        className={clsx(
          classes.iconBox,
          props.classes && props.classes.iconBox && props.classes.iconBox
        )}
      >
        <Box
          className={clsx(
            classes.icon,
            props.minHeight && classes.minHeight,
            classes[props.icon]
          )}
        />
        {props.label && (
          <StyledLabel title={props.label} className={classes.label} />
        )}
      </div>
      <Typography className={classes.title}>{props.title}</Typography>
      {props.description && (
        <Typography className={classes.description}>
          {props.description}
        </Typography>
      )}
      {props.children}
    </div>
  );
};
