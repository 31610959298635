import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'center',
    position: 'relative'
  },
  iconBox: {
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      minWidth: 50
    }
  },
  icon: {
    // margin: "0 auto",
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  minHeight: {
    minHeight: 50
  },
  title: {
    marginTop: 5,
    lineHeight: '21px',
    fontWeight: 'bold',
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      fontSize: '1.15rem'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.95rem'
    }
  },
  description: {
    fontSize: 14,
    lineHeight: '16px'
  },
  label: {
    position: 'absolute',
    top: 38,
    left: 'calc(50% - 15px)'
  },
  basket: {
    width: 41,
    height: 44,
    background: 'url("/images/css_sprites.png") -10px -659px;'
  },
  card: {
    width: 40,
    height: 32,
    background: 'url("/images/css_sprites.png") -10px -10px;'
  },
  care: {
    width: 44,
    height: 40,
    background: 'url("/images/css_sprites.png") -10px -114px;'
  },
  clock: {
    width: 40,
    height: 41,
    background: 'url("/images/css_sprites.png") -10px -414px;'
  },
  cursor: {
    width: 28,
    height: 41,
    background: 'url("/images/css_sprites.png") -10px -475px;'
  },
  customers: {
    width: 50,
    height: 46,
    background: 'url("/images/css_sprites.png") -10px -723px;'
  },
  cute_rocket_launching: {
    width: 50,
    height: 51,
    background: 'url("/images/css_sprites.png") -10px -859px;'
  },
  eye_button: {
    width: 51,
    height: 51,
    background: 'url("/images/css_sprites.png") -10px -930px;'
  },
  feminine_fashion_handbag_for_money: {
    width: 51,
    height: 42,
    background: 'url("/images/css_sprites.png") -10px -597px;'
  },
  full_box: {
    width: 48,
    height: 51,
    background: 'url("/images/css_sprites.png") -10px -1001px;'
  },
  gift: {
    width: 44,
    height: 40,
    background: 'url("/images/css_sprites.png") -10px -174px;'
  },
  gift_lg: {
    width: 50,
    height: 50,
    background: 'url("/images/css_sprites.png") -10px -789px;'
  },
  letter: {
    width: 40,
    height: 32,
    background: 'url("/images/css_sprites.png") -10px -62px;'
  },
  reward: {
    width: 34,
    height: 40,
    background: 'url("/images/css_sprites.png") -10px -234px;'
  },
  sale: {
    width: 40,
    height: 40,
    background: 'url("/images/css_sprites.png") -10px -294px;'
  },
  smile: {
    width: 40,
    height: 40,
    background: 'url("/images/css_sprites.png") -10px -354px;'
  },
  support: {
    width: 51,
    height: 41,
    background: 'url("/images/css_sprites.png") -10px -536px;'
  },
  user_protection: {
    width: 47,
    height: 51,
    background: 'url("/images/css_sprites.png") -10px -1072px;'
  },
  heart: {
    width: 48,
    height: 48,
    background: 'url("/images/css_sprites.png") -71px -0'
  }
}));
