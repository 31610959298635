import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoIcon } from '../../components/InfoIcon';
import { VerifiedByBox } from '../../components/VerifiedByBox';

// make sure icons names are the same as class names in InfoIcon component
const icons = [
  {
    icon: 'gift_lg',
    title: 'Loyalty Rewards',
    description:
      'Everyone Loves Freebies. Get Free Extras on Top of Your Purchase.'
  },
  {
    icon: 'customers',
    title: 'Peace of Mind',
    description: 'More than 53 250 Players trusted Probemas. Join the Gang!'
  },
  {
    icon: 'user_protection',
    title: 'Maximum Safety',
    description: 'Hassle-free transactions and safe in-game trades.'
  },
  {
    icon: 'cute_rocket_launching',
    title: 'Instant Delivery',
    description: '5 Minutes is all it takes... Unless we do it in 3.'
  }
];

const useStyles = makeStyles({
  icon: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    alignItems: 'center',
    padding: 20
  },
  title: {
    marginTop: 5,
    lineHeight: '21px',
    fontWeight: 'bold',
    whiteSpace: 'break-spaces'
  }
});

const TopIcons = () => {
  const classes = useStyles();
  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item xs={12} md={4}>
        <VerifiedByBox />
      </Grid>
      {icons.map(item => (
        <Grid
          item
          xs={6}
          sm={3}
          md={2}
          className={classes.icon}
          key={item.icon}
        >
          <InfoIcon
            key={item.icon}
            icon={item.icon}
            title={item.title}
            description={item.description}
            minHeight
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default TopIcons;
