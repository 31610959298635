import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import HiddenJs from '@material-ui/core/Hidden/HiddenJs';
import dynamic from 'next/dynamic';

import useStorefrontData from 'src/hooks/useStorefrontData';
import TopIcons from 'src/views/Home/TopIcons';

import {useStyles} from './styled';

import PageContent from 'src/components/PageContent';

const LoyaltyInfoBox = dynamic(() => import('src/components/LoyaltyInfoBox'));

// make sure icons names are the same as class names in InfoIcon component
const defaultIcons = [
  {
    icon: 'gift_lg',
    title: 'Rewards',
    description: 'Free Runescape Items in our Loyalty Program'
  },
  {
    icon: 'customers',
    title: 'Confidence',
    description: 'Probemas is a leading RS gold seller since 2013'
  },
  {
    icon: 'user_protection',
    title: 'Safety',
    description: 'Trade only with high-level RS accounts'
  },
  {
    icon: 'cute_rocket_launching',
    title: 'Speed',
    description: 'Fast delivery of purchased RuneScape gold'
  }
];

const Content = ({topIcons, faqTitle}) => {
  const classes = useStyles();
  const icons = topIcons || defaultIcons;
  const {page} = useStorefrontData();
  return (
    <Container className={classes.root}>
      <TopIcons />
      <Divider className={classes.divider} />
      <HiddenJs smUp>
        <div align='center' className={classes.mobileLoyaltyBox}>
          <LoyaltyInfoBox isMobile />
        </div>
      </HiddenJs>

      <HiddenJs smUp>
        <Divider className={classes.divider} />
      </HiddenJs>
      <PageContent page={page} faqTitle={faqTitle} />
      {/* <ImageCards items={PAYMENT_METHODS} /> */}
    </Container>
  );
};

export default Content;
