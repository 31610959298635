import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    marginTop: 30
  },
  icon: {
    alignSelf: 'baseline'
  },
  iconLink: {
    fontSize: 18,
    fontWeight: 500
  },
  divider: {
    marginTop: 15,
    marginBottom: 15
  }
});
