import HelpIcon from '@material-ui/icons/Help';
import clsx from 'clsx';
import { useStyles } from './styled';

export const QuestionBox = props => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.root, props.className)}>
      <HelpIcon fontSize="large" />
      {props.children}
    </div>
  );
};
