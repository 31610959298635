import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useStyles } from './styled';

const StyledLabel = ({ title, className, fontSize, color }) => {
  const classes = useStyles();
  return (
    <span
      className={clsx(
        classes.label,
        classes[color],
        className,
        fontSize === 'small' && classes.small,
        fontSize === 'medium' && classes.medium,
        fontSize === 'large' && classes.large
      )}
    >
      {title}
    </span>
  );
};

StyledLabel.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  fontSize: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.oneOf(['green', 'yellow'])
};
StyledLabel.defaultProps = {
  fontSize: 'medium',
  color: 'green'
};

export default StyledLabel;
