import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import HiddenCss from '@material-ui/core/Hidden/HiddenCss';

const ReviewsWidget = dynamic(() => import('../ReviewsWidget'), { ssr: false });
const LoyaltyInfoBox = dynamic(() => import('../LoyaltyInfoBox'), {
  ssr: false
});
const FAQItems = dynamic(() => import('../FAQItems'), { ssr: false });
const RichTextContent = dynamic(
  () => import('../RichTextContent').then(mod => mod.RichTextContent),
  { ssr: false }
);

const useStyles = makeStyles({
  center: {
    justifyContent: 'center'
  }
});

const PageContent = ({ page, faqTitle, reviews, fullPage }) => {
  const classes = useStyles();
  if (!page) {
    return null;
  }
  return (
    <>
      <Container>
        {!page.isPublished && (
          <Alert severity="warning" className={classes.center}>
            <AlertTitle style={{ textAlign: 'center' }}>
              WARNING! This page is not published yet!
            </AlertTitle>
          </Alert>
        )}
        <RichTextContent htmlContent={page.content} />
      </Container>
      {reviews && <ReviewsWidget reviews={reviews} />}
      <Container>
        {page.faqItems &&
          page.faqItems.edges.length > 0 &&
          (fullPage ? (
            <FAQItems faqItems={page.faqItems.edges} title={faqTitle} />
          ) : (
            <Grid container spacing={3}>
              <Grid item md={4} lg={3} component={HiddenCss} smDown>
                <Box align="center" mt={10}>
                  <LoyaltyInfoBox />
                </Box>
              </Grid>
              <Grid item xs={12} md={8} lg={9}>
                <FAQItems faqItems={page.faqItems.edges} title={faqTitle} />
              </Grid>
            </Grid>
          ))}
      </Container>
    </>
  );
};

PageContent.propTypes = {
  page: PropTypes.object,
  reviews: PropTypes.object
};

export default PageContent;
