import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.common.white,
    display: 'inline-flex',
    verticalAlign: 'middle',
    fontWeight: 'bold',
    padding: 4
  },
  green: {
    backgroundColor: '#1EB940'
  },
  yellow: {
    backgroundColor: '#f2c94c',
    color: theme.palette.primary.dark
  },
  small: {
    fontSize: 10,
    lineHeight: '12px'
  },
  medium: {
    fontSize: 12
  },
  large: {
    fontSize: 14
  }
}));
