import React from 'react';
import Image from 'next/image';
import { Paper, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { QuestionBox } from '../QuestionBox';
import { showZopimDialog } from '../../core/zopim';

export const VerifiedByBox = () => {
  const classes = useStyles();

  const openLiveChat = () => {
    showZopimDialog();
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography className={classes.title} align="center">
          Certified by
        </Typography>
        <div className={classes.imageBox}>
          <Image
            src="/images/mcafee_1.png"
            alt="reviews"
            layout="fill"
            objectFit="contain"
            loading="eager"
          />
        </div>
      </Paper>
      <QuestionBox className={classes.questionBox}>
        <Typography className={classes.innerText} component="div">
          <div>Need assistance?</div>
          <small>
            Contact our 24/7{' '}
            <span className={classes.liveChatLink} onClick={openLiveChat}>
              Live Chat
            </span>
          </small>
        </Typography>
      </QuestionBox>
    </div>
  );
};
